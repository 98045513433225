<template>
  <div>
    <p v-if="message.status == 'deleted'">
      <i>{{message.body}}</i>
    </p>
    <div v-else-if="message.metadata && message.metadata.referral">
      <span style="background-color: #e7e7e7; border-radius: 4px;" class="p-1">
        <a target="_blank" :href="message.metadata.referral.source_url"><i class="mdi mdi-facebook"></i> View Ad</a>
      </span>
      <p class="mt-2">
        {{ message.body }}
      </p>
    </div>
    <template v-else>
      <div v-if="['text', 'system', 'interactive', 'button'].includes(message.body_type) && message.interactive_type != 'call_permission_request'">
        <p v-if="message.interactive_type != 'call_permission_reply'" style="white-space: pre-wrap;" :class="{ 'text-truncate': is_context }" v-html="parseHtmlAnchor(message.body)"></p>
        <p v-else>{{ upperFirst(message.interactive_action.response) }} call permission</p>
      </div>
      <div v-else-if="message.body_type == 'interactive' && message.interactive_type == 'call_permission_request'">
        <div class="row align-items-center">
          <div class="col-1">
            <!-- <font-awesome icon="phone-alt"/> -->
            <FeatherIcon type="phone-call" size="15px" class="mt-2" />
          </div>
          <div class="col-10">
            {{ message.body }}
          </div>
        </div>
      </div>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'image'">
        <FeatherIcon type="camera" size="10px" class="mt-2" v-if="is_context" />
        <img v-if="!is_context" :src="message.body" width="300px" class="cursor-pointer" @click="onClickMediaDetail(message.body, message.body_type)"/>
        <br v-if="message.caption && !is_context"/>{{ message.caption }} {{ is_context ? "Image" : "" }}
      </p>
      <div v-else-if="message.body_type == 'template'">
        <p style="white-space: pre-wrap" v-if="message.header_params">
          <img v-if="message.header_params.type === 'image'" :src="message.header_params.image" width="300px" class="cursor-pointer" @click="onClickMediaDetail(message.header_params.image, message.header_params.type)"/>
          <span v-else-if="message.header_params.type === 'document'">
            <FeatherIcon type="file-text" size="10px" class="mt-2" />
            <a :href="message.header_params.document" target="blank">{{ $t('general.download')}} attachment</a>
          </span>
          <video v-else-if="message.header_params.type === 'video'" :src="message.header_params.video" style="max-width: 350px;" height="auto" controls/>
        </p>
        <p style="white-space: pre-wrap" v-html="parseHtmlAnchor(message.body)">
        </p>
      </div>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'video'">
        <FeatherIcon v-if="is_context" type="video" size="10px" class="mt-2"/>
        <video v-if="!is_context" :src="message.body" style="max-width: 350px;" height="auto" controls/>
        <br v-if="message.caption && !is_context"/> {{ message.caption }}
      </p>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'voice'">
        <FeatherIcon v-if="is_context" type="play-circle" size="10px" class="mt-2"/>
        {{ is_context ? "Voice" : "" }}
        <audio controls v-if="!is_context">
          <source :src="message.body">
          Your browser does not support the audio element.
        </audio>
      </p>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'document'">
        <FeatherIcon type="file-text" size="10px" class="mt-2" />
        {{ message.caption || "Document" }}<br/>
        <a :href="message.body" target="blank">{{ $t('general.download')}}</a>
      </p>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'private_note'">
        <FeatherIcon type="lock" size="10px" class="mt-2" /> {{ message.body }}
      </p>
      <div style="display: flex" v-if="['contacts'].includes(message.body_type)">
        <!-- <p style="white-space: pre-wrap" v-if="['contacts'].includes(message.body_type)"> -->
        <span class="mr-2"><FeatherIcon size="20px" type="user" /></span>
        <p><strong>{{ message.body }}</strong><br/><br/>{{ message.caption }}</p>
      </div>
      <div v-if="['location'].includes(message.body_type)">
        <l-map style="height: 200px; width: 400px" :zoom="map_zoom" :center="map_center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="marker">
            <l-popup :options="{ permanent: true, interactive: true }" v-if="message.caption">
              <div>
                <p>{{ message.caption }}</p>
              </div>
            </l-popup>
          </l-marker>
        </l-map>
        <p>
          <a :href="'https://maps.google.com?q=' + message.body" target="_blank">(Open in Google Maps)</a>
        </p>
      </div>
      <div v-if="message.body_type === 'sticker'">
        <img :src="message.body" width="150px"/>
      </div>
      <div v-if="message.body_type === 'call'">
        <div class="row align-items-center">
          <div class="col-1">
            <!-- <font-awesome icon="phone-alt"/> -->
            <FeatherIcon type="phone-call" size="15px" class="mt-2" />
          </div>
          <div class="col-10">
            <strong>Voice Call</strong><br/>
            <div v-if="message.call_session && message.call_session.duration">{{ humanizeDuration(message.call_session.duration * 1000) }}</div>
            <div v-else-if="message.call_session && !message.call_session.duration">No answer</div>
            <div v-if="message.call_session && message.call_session.duration && message.body.substr(0,4) == 'http'">
              <vue-player audio :sources="{ 'video/webm': message.body }" />
            </div>
          </div>
        </div>
      </div>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'story_mention_image'">
        <img :src="message.body" width="300px" class="cursor-pointer" @click="onClickMediaDetail(message.body, message.body_type)"/>
        <br/><i>Mentioned you at their story</i>
      </p>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'story_mention_video'">
        <video :src="message.body" style="max-width: 350px;" height="auto" controls/>
        <br/><i>Mentioned you at their story</i>
      </p>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'story_reply_image'">
        <i>Reply to your story</i><br/>
        <img :src="message.body" width="300px" class="cursor-pointer" @click="onClickMediaDetail(message.body, message.body_type)"/>
        <br/>{{ message.caption }}
      </p>
      <p style="white-space: pre-wrap" v-else-if="message.body_type == 'story_reply_video'">
        <i>Reply to your story</i><br/>
        <video :src="message.body" style="max-width: 350px;" height="auto" controls/>
        <br/>{{ message.caption }}
      </p>
    </template>
  </div>
</template>
<script>
import {
  LMap, LTileLayer, LMarker, LPopup,
} from 'vue2-leaflet';
import L from 'leaflet';
import { upperFirst } from 'lodash';

const humanizeDuration = require('humanize-duration');

const { latLng } = L;

delete L.Icon.Default.prototype._getIconUrl;
/* eslint-disable global-require */
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  name: 'MessageItem',
  props: ['message', 'is_context', 'zoomImage'],
  data() {
    return {
      map_zoom: 15,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      humanizeDuration,
      upperFirst,
    };
  },
  computed: {
    map_center() {
      if (this.message.body_type === 'location') {
        const [lat, long] = this.message.body.split(',');
        return latLng(lat, long);
      }
      return [];
    },
    marker() {
      if (this.message.body_type === 'location') {
        const [lat, long] = this.message.body.split(',');
        return latLng(lat, long);
      }
      return [];
    },
  },
  methods: {
    onClick(message) {
      this.$emit('handlePreview', message);
    },
    onClickMediaDetail(url, type) {
      this.$emit('handleMediaDetail', url, type);
    },
    // zoomImage(url) {
    //   this.$emit('zoomImage', url);
    // },
    parseHtmlAnchor(text) {
      const rxCommonMarkLink = /(\[([^\]]+)])\(([^)]+)\)/g;
      let string = text ? text.replace(rxCommonMarkLink, '<a target="_blank" href="$3">$2</a>') : text;
      if (string) {
        const htmlFormat = [
          { symbol: '*', tag: 'b' },
          { symbol: '_', tag: 'em' },
          { symbol: '~', tag: 'del' },
          { symbol: '```', tag: 'tt' },
          { symbol: '`', tag: 'mark' },
        ];

        htmlFormat.forEach(({ symbol, tag }) => {
          if (!string) return;
          const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
          const match = string.match(regex);
          if (!match) return;
          match.forEach((m) => {
            let formatted = m;
            /* eslint-disable no-plusplus */
            for (let i = 0; i < 2; i++) {
              formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
            }
            string = string.replace(m, formatted);
          });
        });
        return string;
      }
      return '';
    },
    fileExt(filename) {
      const re = /(?:\.([^.]+))?$/;
      return re.exec(filename)[1];
    },
  },
};
</script>
<style>
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 3em
}
</style>
